import React, { useState, createContext } from "react";

const languages = ["English", "Français"];
const languagesShort = ["en", "fr"];
const defaultState = {
  language: "Français",
  languageShort: "fr",
  languages,
  languagesShort,
  setCurrentLanguage: () => {},
};

const LanguageContext = createContext(defaultState);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("Français");
  const [languageShort, setLanguageShort] = useState("fr");

  const setCurrentLanguage = (lang) => {
    setLanguage(lang);
    setLanguageShort(languagesShort[languages.indexOf(lang)]);
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        languageShort,
        languages,
        languagesShort,
        setCurrentLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
